import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import "../BookingModal/style.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { RadioButton } from "../RadioButton/RadioButton";
import { useCallback, useEffect, useRef, useState } from "react";
import Moment from "moment";
import BookingService from "../../services/booking.service";
import { SignInUpButton } from "../SignInUpButton/SignInUpButton";
import "moment/locale/ar";
import TokenService from "../../services/token.service";

export const BookingModal = ({
  modalOpen,
  setModalOpen,
  item,
  originalType,
  setLoadingFlag,
}) => {
  var days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const [date, setDate] = useState(new Date());
  const [timeslot, setTimeSlot] = useState({});
  const [calendarClassName, setCalendarClass] = useState("d-none");
  const [type, setType] = useState(originalType);
  const [clinics, setClinics] = useState([]);
  const [homeVisit, setHomeVisit] = useState([]);
  const [teleHealth, setTeleHealth] = useState([]);
  const [selectedService, setSelectedService] = useState({});
  const [currentTimeSlots, setCurrentTimeSlots] = useState([]);

  Moment.locale(i18n.language);

  const selectService = useCallback(
    (serviceId) => {
      let selected = [];
      let service = selectedService;
      switch (type) {
        case "clinic":
          if (serviceId) {
            selected = clinics.filter((item) => item?.id === serviceId);
            service = selected.length ? selected[0] : {};
          } else if (
            clinics?.length &&
            Object.keys(selectedService)?.length === 0
          ) {
            service = clinics[0];
          }

          setSelectedService(service);
          break;
        case "home-visit":
          if (serviceId) {
            selected = homeVisit.filter((item) => item?.id);
            service = selected.length ? selected[0] : {};
          } else if (
            homeVisit?.length &&
            Object.keys(selectedService)?.length === 0
          ) {
            service = homeVisit[0];
          }

          setSelectedService(service);
          break;
        case "tele-health":
          if (serviceId) {
            selected = teleHealth.filter((item) => item?.id);
            service = selected.length ? selected[0] : {};
          } else if (
            teleHealth?.length &&
            Object.keys(selectedService)?.length === 0
          ) {
            service = teleHealth[0];
          }

          setSelectedService(service);

          break;
        default:
          break;
      }

      const dayName = days[date.getDay()];
      const TimeSlots = service?.attributes?.working_days?.data
        ?.find((item) => item.attributes?.day === dayName)
        ?.attributes?.timeslots?.data?.filter(
          (time) =>
            Moment(time.attributes.start).format("YYYY/MM/DD") ===
            Moment(date).format("YYYY/MM/DD")
        );
      setCurrentTimeSlots(TimeSlots);
    },
    [setSelectedService, homeVisit, clinics, teleHealth, date]
  );

  useEffect(() => {
    if (item.doctorId) {
      BookingService.getDoctorAvailability(
        item.doctorId,
        setClinics,
        setHomeVisit,
        setTeleHealth,
        type,
        setLoadingFlag
      );
    }

    const handleClickOutside = (event) => {
      if (!event?.target?.className?.includes("react-calendar")) {
        setCalendarClass && setCalendarClass("d-none");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keyup", function (event) {
      const key = event.key; // Or const {key} = event; in ES6+
      if (key === "Escape") {
        handleClickOutside(event);
      }
    });
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setCalendarClass, item]);

  useEffect(() => {
    selectService();
  }, [
    item,
    clinics?.length,
    teleHealth?.length,
    homeVisit?.length,
    type,
    selectService,
    date,
  ]);

  useEffect(() => {
    setDate(new Date());
    setTimeSlot("");
    setCalendarClass("d-none");
    setType(originalType);
    setClinics([]);
    setHomeVisit([]);
    setTeleHealth([]);
    setSelectedService({});
    setCurrentTimeSlots([]);
  }, [item?.doctorId]);

  function addAppointment() {
    const data = {
      type,
      doctorId: item?.doctorId,
      timeslot,
      selectedService,
      date: Moment(date).format("YYYY/MM/DD"),
      img: item?.profilePicture,
      name:
        i18n.language === "ar"
          ? `د.${item.firstname_ar} ${item.lastname_ar}`
          : `Dr. ${item.firstname} ${item.lastname}`,
    };
    var appointments = [];
    appointments.push(data);
    localStorage.setItem("appointments", JSON.stringify(appointments));
    if (TokenService.getUser()?.JWTtoken && TokenService.getUser()?.confirmed) {
      window.location.pathname = `/${type}-booking`;
    } else if (
      TokenService.getUser()?.JWTtoken &&
      !TokenService.getUser()?.confirmed
    ) {
      window.location.pathname = "/verify";
    } else {
      window.location.pathname = "/login";
    }
  }

  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(238, 238, 238)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "88%",
      height: "fit-content",
      maxWidth: "833px",
      top: "3rem",
      marginRight: "auto",
      marginLeft: "auto",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };
  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="Booking"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="position-relative mt-3">
        <img  loading="lazy"
          src="./img/close.svg"
          className="close-img"
          onClick={() => {
            setModalOpen(false);
            setType(originalType);
            document.body.style.overflow = "auto";
          }}
        />
        <div className="d-flex gap-3 doctor_nam">
          <p className="m-auto text-center">
            {i18n.language === "ar"
              ? `د.${item.firstname_ar} ${item.lastname_ar}`
              : `Dr. ${item.firstname} ${item.lastname}`}
          </p>
        </div>
        <div className="d-flex gap-4 my-3 flex-wrap">
          <RadioButton
            name="type"
            id="clinic-radio"
            type="clinic"
            label={t("header.clinic")}
            currentType={type}
            onChange={() => setType("clinic")}
            disabled={clinics?.length === 0}
            labelClassName="modal-checkbox-label"
          />
          <RadioButton
            name="type"
            id="telehealth-radio"
            type="tele-health"
            label={t("header.telehealth")}
            currentType={type}
            onChange={() => setType("tele-health")}
            disabled={teleHealth?.length === 0}
            labelClassName="modal-checkbox-label"
          />
          <RadioButton
            name="type"
            id="home-visit-radio"
            type="home-visit"
            label={t("header.homevisit")}
            currentType={type}
            onChange={() => setType("home-visit")}
            disabled={homeVisit?.length === 0}
            labelClassName="modal-checkbox-label"
          />
        </div>
        <div className="d-flex gap-3">
          <div className="d-flex time-slots-container flex-column mb-2">
            <div>
              <div className="d-flex gap-2">
                <img  loading="lazy" src="./img/info-icon.png" className="about-icon" />
                <p className="select_tim">{t("about.doctor")}</p>
              </div>
              <div className="px-4 mx-2">
                {selectedService?.attributes?.doctor?.data?.attributes?.about}
              </div>
            </div>
            <div>
              <div className="d-flex gap-2">
                <img  loading="lazy" src="./img/edu-icon.png" className="about-icon" />
                <p className="select_tim">{t("academic.qualification")}</p>
              </div>
              <div className="px-4 mx-2">
                {
                  selectedService?.attributes?.doctor.data?.attributes
                    ?.academicQualifications
                }
              </div>
            </div>
            <div>
              <div className="d-flex gap-2">
                <img  loading="lazy" src="./img/doctor-icon.png" className="about-icon" />
                <p className="select_tim">{t("doctor.grade")}</p>
              </div>
              <div className="px-4 mx-2">
                {item?.doctor_titles?.length > 0 &&
                item?.doctor_titles[0]?.title
                  ? i18n.language === "ar"
                    ? `${item?.doctor_titles[0]?.title_ar}`
                    : `${item?.doctor_titles[0]?.title}`
                  : "-"}
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex gap-2 flex-wrap">
              {type === "clinic" &&
                clinics.map((item, key) => (
                  <div
                    className={`d-flex gap-1 time-slot fit-content ${
                      selectedService.id === item.id ? "selected-time-slot" : ""
                    }`}
                    key={`${key}-clinic-name`}
                    onClick={() => selectService(item.id)}
                  >
                    {
                      item?.attributes?.clinic_service?.data?.attributes?.clinic?.data
                        ?.attributes?.name
                    }
                  </div>
                ))}
            </div>
            <div className="mt-2">
              {type === "clinic" &&
              selectedService?.attributes?.clinic_service?.data?.attributes
                ?.clinic?.data?.attributes?.address?.data
                ? selectedService?.attributes?.clinic_service?.data?.attributes
                    ?.clinic?.data?.attributes?.address?.data?.attributes
                    ?.street
                : ""}
            </div>
            <div
              className="d-flex flex-column fit-content my-2 pointer"
              onClick={() => setCalendarClass("")}
            >
              <div className="d-flex gap-3 ">
                <img  loading="lazy" src="./img/calendar_today.svg" />
                <p className="mb-0 select_tim">{t("selectdate")}</p>
              </div>
              <p className="date">
                {Moment(date)
                  .local(i18n.language ? i18n.language : "en")
                  .format("YYYY/MM/DD")}
              </p>
            </div>
            <Calendar
              onChange={(e) => {
                setDate(e);
                setCalendarClass("d-none");
              }}
              value={date}
              minDate={new Date()}
              className={calendarClassName}
              ref={ref}
            />
          </div>
        </div>
        <div className="d-flex gap-3 fit-content mb-2">
          <img  loading="lazy" src="./img/access_time.svg" />
          <p className="mb-0 select_tim">{t("selecttimeslot")}</p>
        </div>
        <div className="d-flex flex-wrap time-slots-container">
          {currentTimeSlots?.length > 0 ? (
            currentTimeSlots?.map((time, key) =>
              time?.attributes?.status === "available" ? (
                <div
                  className={`d-flex gap-3 time-slot ${
                    time.id === timeslot.id ? "selected-time-slot" : ""
                  }`}
                  key={`time-slot-${key}`}
                  onClick={() => setTimeSlot(time)}
                >
                  {new Date(time?.attributes?.start)?.toLocaleString(
                    `${i18n.language}-EG`,
                    {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }
                  )}
                </div>
              ) : (
                ""
              )
            )
          ) : (
            <p className="modal-checkbox-label m-auto">{t("notimeslots")}</p>
          )}
        </div>
      </div>
      <SignInUpButton
        state={
          Object.keys(selectedService)?.length === 0 ||
          Object.keys(timeslot)?.length === 0
            ? "greyed-out"
            : "default"
        }
        className={"book-button py-4 mt-3 book-btn-modal"}
        name={t("doctor.book")}
        onClick={() => addAppointment()}
      />
    </ReactModal>
  );
};
