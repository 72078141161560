const SeoScreens = [
  {
    name: "Home page",
  },
  {
    name: "Clinics page",
  },
  {
    name: "Telehealth page",
  },
  {
    name: "Home visit page",
  },
  {
    name: "Nursing page",
  },
  {
    name: "Pharma page",
  },
  {
    name: "Pharma products page",
  },
  {
    name: "Family plans page",
  },
  {
    name: "Personal plans page",
  },
];
export default SeoScreens;
