import { useEffect, useState } from "react";
import GettingService from "../../services/getting.service";
import { FeatureBanner } from "../../components/FeatureBanner/FeatureBanner";
import { useTranslation } from "react-i18next";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { MetaTags } from "../../components/MetaTags/MetaTags";

const ScanLab = ({ setLoadingFlag }) => {
  const [suppliers, setSuppliers] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("lab");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  async function getNeededData() {
    await GettingService.getSuppliers(
      setSuppliers,
      selectedFilter,
      setLoadingFlag
    );
  }

  useEffect(() => {
    getNeededData();
  }, [selectedFilter]);
  return (
    <>
      <MetaTags title={`${t("doxx")} - ${t("header.labscans")}`} description={`${t('scanlab.title2')} ${t('scanlab.desc')}`}/>
      <div className="nursing">
        <FeatureBanner
          type="scan"
          color="bg-scan"
          title1={t("scanlab.title")}
          title2={t("scanlab.title2")}
          desc={t("scanlab.desc")}
          desc2={""}
          imgSrc="./img/woman-holding-blood-sample-microscope-cutout 1.png"
        />
        <div className="btns-labs-scan">
          <div className="d-flex gap-3 mt-3 mx-auto px-3">
            <div
              onClick={() => setSelectedFilter("lab")}
              className={`d-flex gap-1 time-slot fit-content ${
                selectedFilter === "lab" ? "selected-time-slot" : ""
              } pointer`}
            >
              {t("header.lab")}
            </div>
            <div
              className={`d-flex gap-1 time-slot fit-content pointer ${
                selectedFilter === "scan" ? "selected-time-slot" : ""
              }`}
              onClick={() => setSelectedFilter("scan")}
            >
              {t("scan")}
            </div>
          </div>
        </div>

        <div className="choose-service-provider">{t("choose.provider")}</div>
        <div className="suppliers-carousel d-flex gap-3 px-3 flex-wrap mt-3 justify-content-center">
          {suppliers?.map((item, key) => (
            <div
              className="service-provider-button width-fit pointer"
              onClick={() =>
                navigate(`/${selectedFilter}-details`, {
                  state: {
                    supplierId: item?.id,
                  },
                })
              }
              key={`provider-${key}`}
            >
              <div className="provider-content mt-3 px-3">
                {item?.attributes?.profile?.data?.attributes?.imageUrl ? (
                  <img  loading="lazy"
                    src={item?.attributes?.profile?.data?.attributes?.imageUrl}
                    alt="scan-img"
                    className="scan-img"
                  />
                ) : (
                  <p>
                    {i18n.language === "ar" && item?.attributes?.name_ar
                      ? item?.attributes?.name_ar
                      : item?.attributes?.name}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ScanLab;
