import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import { getData } from "../../services/apiService";
import i18n from "../../i18n";
import { useNavigate, useParams } from "react-router-dom";
import { BookingModal } from "../../components/BookingModal/BookingModal";

export const Doctor = ({ setLoadingFlag }) => {
  const [doctorProfile, setDoctorProfile] = useState();
  const [services, setServices] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [item, setItem] = useState({});
  const originalType = "clinic";

  function clickBook() {
    setItem(services?.length ? services[0] : {});
    setModalOpen(true);
    document.body.style.overflow = "hidden";
  }

  const { id } = useParams();
  const navigate = useNavigate();

  async function getNeededData() {
    setLoadingFlag(true);
    const params = {
      "populate[clinic_service][populate]": "clinic.address",
      "populate[homevisit_service][populate]": "cities",
      "filters[doctor][id][$eq]": id,
      "populate[doctor][populate][profile]": "*",
      "populate[doctor][populate][doctor_title]": "*",
      "populate[doctor][populate][specialities]": "*",
      "populate[working_days][populate][timeslots][filters][start][$gt]":
        new Date(),
      locale: localStorage.getItem("lang"),
    };
    await getData("/doctor-services", params)
      .then((response) => {
        const data = response?.data;
        console.log(data);
        if (data?.length) {
          setServices(data);
          setDoctorProfile(data[0]?.attributes?.doctor?.data?.attributes);
          setLoadingFlag(false);
        } else {
          navigate("/404");
        }
      })
      .catch(() => setLoadingFlag(false));
  }

  useEffect(() => {
    getNeededData();
  }, []);

  return (
    <>
      <MetaTags
        type="doctor page"
        title={`${t("doxx")} - ${t("header.pharmacy")}`}
        description={`${t("order.in.seconds")} ${t("submit.your.order")}`}
      />
      {/* <BookingModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        item={item}
        originalType={originalType}
        setLoadingFlag={setLoadingFlag}
      /> */}
      <div className="doctor-bg">
        <div className="height-fit">
          <div className="d-flex doctor-div gap-3">
            <img  loading="lazy"
              className="image-placeholder-icon ml-auto doctor-img-info"
              alt="doctor-img"
              src={
                doctorProfile?.profile?.data?.attributes?.imageUrl
                  ? doctorProfile?.profile?.data?.attributes?.imageUrl
                  : "./img/doctor-placeholder.jpg"
              }
            />
            <div className="width55per mx-3">
              <h2 className="doctor_nam mb-3">
                {i18n.language === "ar" &&
                doctorProfile?.profile?.data?.attributes?.firstname_ar
                  ? `د.${doctorProfile?.profile?.data?.attributes?.firstname_ar} ${doctorProfile?.profile?.data?.attributes?.lastname_ar}`
                  : `Dr. ${doctorProfile?.profile?.data?.attributes?.firstname} ${doctorProfile?.profile?.data?.attributes?.lastname}`}
              </h2>
              <div className="time-slots-container light-blue-bg mb-1">
                <div>
                  <div className="d-flex gap-2">
                    <img  loading="lazy" src="./img/info-icon.png" className="about-icon" />
                    <p className="select_tim mb-0">{t("about.doctor")}</p>
                  </div>
                  <p className="px-4 m-2">{doctorProfile?.about}</p>
                  <div>
                    <div className="d-flex gap-2">
                      <img  loading="lazy" src="./img/edu-icon.png" className="about-icon" />
                      <p className="select_tim mb-0">
                        {t("academic.qualification")}
                      </p>
                    </div>
                    <div className="px-4 m-2">
                      {doctorProfile?.academicQualifications}
                    </div>
                  </div>
                  <div>
                    <div className="d-flex gap-2">
                      <img  loading="lazy"
                        src="./img/diagnosis-svgrepo-com.svg"
                        className="about-icon"
                      />
                      <p className="select_tim mb-0">{t("specialties")}</p>
                    </div>
                    <div className="px-4 m-2">
                      {doctorProfile?.specialities?.data?.filter((spec) =>
                        i18n?.language
                          ? spec?.attributes?.locale === i18n?.language
                          : spec?.attributes?.locale === "en"
                      )?.length > 1
                        ? `${
                            doctorProfile?.specialities?.data?.filter((spec) =>
                              i18n?.language
                                ? spec?.attributes?.locale === i18n?.language
                                : spec?.attributes?.locale === "en"
                            )[0]?.attributes?.name
                          } , ${
                            doctorProfile?.specialities?.data?.filter((spec) =>
                              i18n?.language
                                ? spec?.attributes?.locale === i18n?.language
                                : spec?.attributes?.locale === "en"
                            )[1]?.attributes?.name
                          }`
                        : doctorProfile?.specialities?.data?.filter((spec) =>
                            i18n?.language
                              ? spec?.attributes?.locale === i18n?.language
                              : spec?.attributes?.locale === "en"
                          )?.length
                        ? doctorProfile?.specialities?.data?.filter((spec) =>
                            i18n?.language
                              ? spec?.attributes?.locale === i18n?.language
                              : spec?.attributes?.locale === "en"
                          )[0]?.name
                        : "-"}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex gap-2">
                    <img  loading="lazy" src="./img/doctor-icon.png" className="about-icon" />
                    <p className="select_tim mb-0">{t("doctor.grade")}</p>
                  </div>
                  <div className="px-4 m-2">
                    {doctorProfile?.doctor_title?.data &&
                    doctorProfile?.doctor_title?.data?.attributes?.title
                      ? i18n.language === "ar"
                        ? `${doctorProfile?.doctor_title?.data?.attributes?.title_ar}`
                        : `${doctorProfile?.doctor_title?.data?.attributes?.title}`
                      : "-"}
                  </div>
                </div>
                <div>
                  <div className="d-flex gap-2">
                    <img  loading="lazy"
                      src="./img/ambulance-svgrepo-com.svg"
                      className="about-icon"
                    />
                    <p className="select_tim mb-0">{t("services")}</p>
                  </div>
                  <div className="px-4 m-2">
                    {services?.map((service) => (
                      <p>{t(service?.attributes?.serviceType)}</p>
                    ))}
                  </div>
                </div>
                <div>
                  <div className="d-flex gap-2">
                    <img  loading="lazy"
                      src="./img/help-svgrepo-com.svg"
                      className="about-icon"
                    />
                    <p className="select_tim mb-0">{t("practisingSince")}</p>
                  </div>
                  <div className="px-4 m-2">
                    {doctorProfile?.practisingSince}
                  </div>
                </div>
                <div>
                  <div className="d-flex gap-2">
                    <img  loading="lazy"
                      src="./img/championship-award_11300107.png"
                      className="about-icon"
                    />
                    <p className="select_tim mb-0">{t("awards")}</p>
                  </div>
                  <div className="px-4 m-2">
                    {doctorProfile?.awards ? doctorProfile?.awards : "-"}
                  </div>
                </div>
              </div>
              {/* <div onClick={() => clickBook()} className="width100per mt-auto mb-3">
                <div className="book-button">
                  <div className="book">{t("doctor.book")}</div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
