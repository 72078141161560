import "./Clinic.css";
import { DoctorCards } from "../../components/DoctorCards/DoctorCards";
import { FilterSearch } from "../../components/FilterSearch/FilterSearch";
import { FeatureBanner } from "../../components/FeatureBanner/FeatureBanner";
import { useCallback, useEffect, useState } from "react";
import SearchService from "../../services/search.service";
import { useLocation } from "react-router-dom";
import { BookingModal } from "../../components/BookingModal/BookingModal";
import { useTranslation } from "react-i18next";
import { MetaTags } from "../../components/MetaTags/MetaTags";

const Clinic = ({ setLoadingFlag }) => {
  const { state } = useLocation();
  const [result, setResult] = useState({});
  const [selectedRegion, setSelectedRegion] = useState(state?.city);
  const [selectedSpecialty, setSelectedSpecialty] = useState(state?.speciality);
  const [selectedGrade, setSelectedGrade] = useState();
  const [searchName, setSearchName] = useState(state?.searchWord);
  const [gender, setGender] = useState("");
  const [offset, setOffset] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [item, setItem] = useState({});
  const originalType = 'clinic';
  const {i18n, t} = useTranslation();
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search).get('search');

  const search = useCallback(async () => {
    if(!searchName && searchParam){
      setSearchName(searchParam);
    }
    
    await SearchService.searchForClinic(
      {
        city: selectedRegion,
        speciality: selectedSpecialty,
        doctor_title: selectedGrade,
        searchWord: searchName || searchParam,
        gender,
        limit: 8,
        offset: offset,
        locale: i18n.language,
        "coordinates[lat]": position?.latitude,
        "coordinates[long]": position?.longitude
      },
      (response) => {
        setResult(response);
      },
      setLoadingFlag
    );
  }, [
    setLoadingFlag,
    gender,
    offset,
    selectedRegion,
    selectedSpecialty,
    searchName,
    offset,
    selectedGrade,
    setResult,
  ]);

  const [position, setPosition] = useState({ latitude: null, longitude: null });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  useEffect(() => {
    search();
  }, [search, i18n.language, position?.latitude, position?.longitude, searchName]);

  useEffect(()=>{
    setOffset(1);
  },[
    gender,
    selectedRegion,
    selectedSpecialty,
    searchName
  ])

  return (
    <>
      {
        searchName && result?.result?.length ?
        <MetaTags type="Clinics page" title={ `${t('doxx')} - ${searchName}`} description={`${t("clinic.banner.title2")} ${t("clinic.banner.desc")} - ${searchName}`} />
        : <MetaTags type="Clinics page" title={ `${t('doxx')} - ${t('header.clinic')}`} description={`${t("clinic.banner.title2")} ${t("clinic.banner.desc")}`} />
      }
      
      <div className="clinic-div d-flex">
        <FilterSearch
          setLoadingFlag={setLoadingFlag}
          setSearchName={setSearchName}
          setSelectedSpecialty={setSelectedSpecialty}
          setSelectedRegion={setSelectedRegion}
          setGender={setGender}
          searchName={searchName}
          selectedSpecialty={selectedSpecialty}
          selectedRegion={selectedRegion}
          selectedGrade={selectedGrade}
          setSelectedGrade={setSelectedGrade}
          gender={gender}
          search={search}
          total={result?.orginalTotal ? result?.orginalTotal : 0}
        />
        <div className="d-flex flex-column cards-doctors-div">
          <FeatureBanner type="clinic" title1={t("clinic.banner.title")} title2={t("clinic.banner.title2")} desc={t("clinic.banner.desc")} imgSrc={"/img/dr-isolated-2@2x.png"}/>
          <DoctorCards
            result={result}
            limit={8}
            setOffset={setOffset}
            offset={offset}
            setModalOpen={setModalOpen}
            setItem={setItem}
            type="clinic"
          />
        </div>
      </div>
      <BookingModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        item={item}
        originalType={originalType}
        setLoadingFlag={setLoadingFlag}
      />
    </>
  );
};

export default Clinic;
