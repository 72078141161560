import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Categories = ({
  categories,
  property1
}) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  
  return (
    <div className="categories">
      {categories?.map((item, key) => (
         <div key={`cat-${key}`} onClick={()=>navigate(`/pharmacy-details`, {
          state: {
           categoryId: item?.id
          },
        })} className={`rectangle-2 d-flex pointer justify-content-center align-items-center property-1-${property1}`}>
          <p className="text-center ">
              {i18n.language === "ar" && item?.attributes?.name_ar
              ? item?.attributes?.name_ar
              : item?.attributes?.name}
          </p>
          <img  loading="lazy" src={item?.attributes?.image?.data  ? item?.attributes?.image?.data?.attributes?.url : "./img/image-placeholder.png"} alt="category image" className="cat-img"
          />
        </div>         
      ))}
    </div>
  );
};
