import { FilterSearch } from "../../components/FilterSearch/FilterSearch";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PharmaCard } from "../../components/PharmaCard/PharmaCard";
import GettingService from "../../services/getting.service";
import ReactPaginate from "react-paginate";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";
import { MetaTags } from "../../components/MetaTags/MetaTags";

const PharmacyList = ({ setLoadingFlag }) => {
  const { state } = useLocation();
  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(8);
  const [result, setResult] = useState({});
  const [searchName, setSearchName] = useState(state?.searchWord);
  const [offset, setOffset] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    state?.categoryId
  );

  const [items, setItems] = useState([]);
  const originalType = "home-visit";
  const { i18n, t } = useTranslation();

  const search = useCallback(async () => {
    await GettingService.getProductsByCategory(
      selectedCategoryId,
      setItems,
      "pharmacy",
      setLoadingFlag,
      offset,
      searchName,
      setTotal
    );
  }, [setLoadingFlag, searchName, selectedCategoryId, offset, setResult]);

  useEffect(() => {
    search();
  }, [search, i18n.language]);

  useEffect(() => {
    setOffset(1);
  }, [searchName, selectedCategoryId]);

  return (
    <>
    <MetaTags type="Pharma products page" title={`${t("doxx")} - ${t("header.pharmacy")}`} description={`${t("genuine.products")} ${t("all.products.medicine")}`}/>
      <div className="home-visit-div d-flex">
        <FilterSearch
          setLoadingFlag={setLoadingFlag}
          setSearchName={setSearchName}
          searchName={searchName}
          search={search}
          type="pharmacy"
          selectedCategoryId={selectedCategoryId}
          setCategoryId={setSelectedCategoryId}
          total={total ? total : 0}
        />
        <div className="d-flex flex-column cards-doctors-div mt-4">
          <div className="doctor-cards mb-4 pt-3">
            {items?.map((item, key) => (
              <PharmaCard
                key={`pharma-card-${key}`}
                name={item?.attributes?.name}
                price={item?.attributes?.price}
                name_ar={item?.attributes?.name_ar}
                id={item?.id}
              />
            ))}
            <div className="pagination-container">
              <ReactPaginate
                breakLabel="..."
                nextLabel={t("pagination.next")}
                onPageChange={(e) => {
                  setOffset(e.selected + 1);
                }}
                pageRangeDisplayed={limit}
                pageCount={Math.ceil(total / limit)}
                previousLabel={t("pagination.prev")}
                renderOnZeroPageCount={null}
                disabledClassName="greyed-out"
                pageLabelBuilder={(p) => ConvertToArabicNumbers(p)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PharmacyList;
