import "./style.css";
import { DoctorCards } from "../../components/DoctorCards/DoctorCards";
import { FilterSearch } from "../../components/FilterSearch/FilterSearch";
import { FeatureBanner } from "../../components/FeatureBanner/FeatureBanner";
import { useCallback, useEffect, useState } from "react";
import SearchService from "../../services/search.service";
import { useLocation } from "react-router-dom";
import { BookingModal } from "../../components/BookingModal/BookingModal";
import { useTranslation } from "react-i18next";
import { MetaTags } from "../../components/MetaTags/MetaTags";

const TeleHealth = ({ setLoadingFlag }) => {
  const { state } = useLocation();
  const [result, setResult] = useState({});
  const [selectedRegion, setSelectedRegion] = useState(state?.city);
  const [selectedSpecialty, setSelectedSpecialty] = useState(state?.speciality);
  const [searchName, setSearchName] = useState(state?.searchWord);
  const [selectedGrade, setSelectedGrade] = useState();
  const [gender, setGender] = useState("");
  const [offset, setOffset] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [item, setItem] = useState({});
  const originalType = "tele-health";
  const { i18n, t } = useTranslation();

  const search = useCallback(async () => {
    await SearchService.searchForTelehealth(
      {
        city: selectedRegion,
        speciality: selectedSpecialty,
        searchWord: searchName,
        doctor_title: selectedGrade,
        gender,
        limit: 8,
        offset,
        locale: i18n.language,
      },
      (response) => {
        setResult(response);
      },
      setLoadingFlag
    );
  }, [
    setLoadingFlag,
    gender,
    offset,
    selectedRegion,
    selectedSpecialty,
    selectedGrade,
    searchName,
    setResult,
  ]);

  useEffect(() => {
    search();
  }, [search, i18n.language]);

  useEffect(() => {
    setOffset(1);
  }, [gender, selectedRegion, selectedSpecialty, searchName]);

  return (
    <>
      {
        searchName && result?.result?.length ?
        <MetaTags type="Telehealth page" title={ `${t('doxx')} - ${searchName}`} description={`${t("telehealth.banner.title2")} ${t("telehealth.banner.desc")} - ${searchName}`} />
        : <MetaTags type="Telehealth page" title={`${t("doxx")} - ${t("header.telehealth")}`} description={`${t("telehealth.banner.title2")} ${t("telehealth.banner.desc")}`} />
      }
      <div className="tele-health-div d-flex">
        <FilterSearch
          setLoadingFlag={setLoadingFlag}
          setSearchName={setSearchName}
          setSelectedSpecialty={setSelectedSpecialty}
          setSelectedRegion={setSelectedRegion}
          setGender={setGender}
          searchName={searchName}
          selectedSpecialty={selectedSpecialty}
          selectedGrade={selectedGrade}
          setSelectedGrade={setSelectedGrade}
          selectedRegion={selectedRegion}
          gender={gender}
          search={search}
          total={result?.orginalTotal ? result?.orginalTotal : 0}
        />
        <div className="d-flex flex-column cards-doctors-div">
          <FeatureBanner
            type="tele-health"
            title1={t("telehealth.banner.title")}
            title2={t("telehealth.banner.title2")}
            desc={t("telehealth.banner.desc")}
            desc2={t("telehealth.banner.desc2")}
            imgSrc={"./img/teleconsultation cutout 1.png"}
          />
          <DoctorCards
            result={result}
            limit={8}
            setOffset={setOffset}
            offset={offset}
            setModalOpen={setModalOpen}
            setItem={setItem}
            type="tele-health"
          />
          <BookingModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            item={item}
            originalType={originalType}
            setLoadingFlag={setLoadingFlag}
          />
        </div>
      </div>
    </>
  );
};

export default TeleHealth;
