import { useTranslation } from "react-i18next";
import "./style.css";
import TokenService from "../../services/token.service";
import { AskDoctor } from "../AskDoctor/AskDoctor";
import { useNavigate } from "react-router-dom";

export const Footer = ({ display }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={"footer flex-wrap " + display}>
      <AskDoctor />
      <div className="logostore-buttons">
        <a href="/">
          <img  loading="lazy" className="logo-icon" alt="" src="/img/logo.svg" />
        </a>
      </div>
      <div className="social-media">
        <div className="social-media1">
          <div className="contact-us">{t("footer.socialmedia")}</div>
        </div>
        <div className="socials-icons">
          <a
            href="https://www.linkedin.com/company/doxxcare"
            rel="noreferrer"
            target="_blank"
          >
            <img  loading="lazy"
              className="keyboard-arrow-up-icon"
              alt="linkedin-icon"
              src="/img/icon_linkedin.svg"
            />
          </a>
          <a
            href="https://www.instagram.com/doxx.care"
            rel="noreferrer"
            target="_blank"
          >
            <img  loading="lazy"
              className="keyboard-arrow-up-icon"
              alt="instagram-icon"
              src="/img/icon_instagram.svg"
            />
          </a>
          <a
            href="https://www.facebook.com/doxx.care"
            rel="noreferrer"
            target="_blank"
          >
            <img  loading="lazy"
              className="icon-facebook"
              alt=""
              src="/img/icon_facebook.svg"
            />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=201200001774&data=AWB1iNAi_MkyAqINbBa5YH5BpqLFIwZ0B4W4MX8zTVMVVMoeQtkFwc2ayXS0Vofi5h5KThO9akD48rSjuviVGjcExnSMVd5xCyaDXxns8kztW15EtCvgBubzpIQgEl8Zo_pkTCtjLQ-gTzhwv55mMMmL6WtOyCeMp_oKzyHdIUEoBwKZ_Mg-k0EEr8mE9b9mL4RSh7egAejztS70dULH-8Vrl6GlMAtnU0vnPR6SI3eoZEq-IQAVeOEymlGHICQE3T2cWvE1n3CSSoLmPzgw90xwLEeluR2HDqQNuc4CDq_EybZ120bynEmbGn2ecvnNyEM&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR0S8122jn399ESr05pLse8AyzdScMd4B26UJG4fbvCmJenOuku1Rao3QuE"
            rel="noreferrer"
            target="_blank"
          >
            <img  loading="lazy"
              className="icon-whatsapp"
              alt=""
              src="/img/icon_whatsapp.svg"
            />
          </a>
          <a
            href="https://twitter.com/Doxxcare"
            rel="noreferrer"
            target="_blank"
          >
            <img  loading="lazy" className="icon-twitter" alt="" src="/img/icon.svg" />
          </a>
        </div>
      </div>
      <div className="social-media">
        <div className="social-media1">
          <div className="contact-us">{t("footer.fordoctors")}</div>
        </div>
        <div className="for-doctors-items">
          {!TokenService.getUser()?.JWTtoken ? (
            <a href="/login" className="white">
              <div className="social-media1">
                <div className="submit-a-medical-container">
                  {t("header.siginuplogin")}
                </div>
              </div>
            </a>
          ) : (
            ""
          )}
          <div className="social-media1">
            <div className="submit-a-medical-container">
              {t("footer.setupclinic")}
            </div>
          </div>
          <div className="social-media1">
            <div className="submit-a-medical-container">
              {t("footer.freqaskquestions")}
            </div>
          </div>
        </div>
      </div>
      <div className="social-media">
        <div className="social-media1">
          <div className="contact-us">{t("footer.contactuss")}</div>
        </div>
        <div className="for-doctors-items1">
          <div className="social-media1">
            <div className="submit-a-medical-container">
              {t("footer.address")}
            </div>
          </div>
          <div className="social-media1">
            <div className="submit-a-medical-container">
              {t("footer.patientphone")}
            </div>
          </div>
          <div className="social-media1">
            <div className="submit-a-medical-container">
              {t("footer.doctorphone")}
            </div>
          </div>
          <div className="social-media1">
            <div className="submit-a-medical-container">info@doxx.care</div>
          </div>
        </div>
      </div>
      <div className="social-media">
        <div className="social-media1">
          <div className="contact-us">{t("about")}</div>
        </div>
        <div className="for-doctors-items1">
          <div className="social-media1">
            <div className="d-flex flex-column">
              <div className="submit-a-medical-container pointer" onClick={()=>navigate("/privacy-policy")}>
                {t("privacypolicy")}
              </div>
              <div className="submit-a-medical-container pointer mt-2" onClick={()=>navigate("/blogs")}>
                {t("blogs")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="social-media">
        <div className="social-media1">
          <div className="contact-us">{t("footer.scanme")}</div>
        </div>
        <div className="for-doctors-items1">
          <div className="social-media1">
            <div className="submit-a-medical-container">
              <img  loading="lazy"
                src="./img/qr-code_page-0001.jpg"
                alt="qr-code"
                className="qr-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="social-media">
        <div className="social-media1">
          <div className="contact-us">{t("footer.ourapp")}</div>
        </div>
        <div className="for-doctors-items1">
          <div className="social-media1 flex-column">
            <a
              href="https://play.google.com/store/apps/details?id=com.openner.doxx_patient&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="submit-a-medical-container">
                <img  loading="lazy" src="./img/google-play-badge.png" alt="google-play-link" />
              </div>
            </a>
            <a
              href="https://apps.apple.com/us/app/doxx-patient/id1579305823"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="submit-a-medical-container">
                <img  loading="lazy"
                  src="./img/Download_on_the_App_Store_Badge.png"
                  alt="app-store-link"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
